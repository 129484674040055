<template>
  <v-footer padless>
    <v-card flat tile class="black--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-1 blacks--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
        <v-btn
          plain
          class="blacks--text text-none mx-1 pa-0"
          href="https://www.nos.pt/ajuda/linha-de-apoio-cliente#linhas?tabId=linhas-de-apoio"
          target="_blank"
        >
          Contactos
        </v-btn>
        <v-btn
          plain
          class="blacks--text text-none mx-2 pa-0"
          href=""
          target="_blank"
          @click="handleCookies"
        >
          Configurar cookies
        </v-btn>
      </v-card-text>

      <!-- <v-card-text class="pa-1">
        <v-btn
          plain
          class="blacks--text text-none"
          href=""
          target="_blank"
          @click="handleCookies"
        >
          Configurar cookies
        </v-btn>
      </v-card-text> -->

      <!-- <v-divider></v-divider> -->

      <v-card-text class="blacks--text pt-0 pb-1">
        {{ new Date().getFullYear() }} @ Messaging PRO -
        <strong> NOS, todos os direitos reservados</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    $cookiebot: "",
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/nosportugal/",
      },
      {
        text: "mdi-youtube",
        link: "https://www.youtube.com/NOSPortugal",
      },
      {
        text: "mdi-linkedin",
        link: "https://www.linkedin.com/company/nos-sgps",
      },
      {
        text: "mdi-instagram",
        link: "https://www.instagram.com/nosportugal/",
      },
    ],
  }),
  methods: {
    handleCookies(event) {
      event.preventDefault();
      window.Cookiebot.renew();
    },
  },
};
</script>
