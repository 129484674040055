import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/lib/services/goto";

import Home from "@/Home.vue";
import Documentation from "@/components/Documentation.vue";

import keycloakInstance, { initOptions } from "./keycloak";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/documentation",
    name: "documentation",
    component: Documentation,
  },
];

const router = new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // var keycloak = Keycloak();
  keycloakInstance.init({ onLoad: initOptions.onLoad }).then((auth) => {
    if (!auth) {
      // console.log("NOT LOGGED");
      next();
    } else {
      // console.log("LOGGED");
      window.location.href = process.env.VUE_APP_MPRO_HOME;
    }
  });

  // console.log("PATH:" + to.path);
});

Vue.use(Router);

export default router;
