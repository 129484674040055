<template>
  <v-app>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0 pt-15">
      <div class="pt-5">
        <!-- <redoc spec-url="./api.yaml"></redoc> -->
        <!-- <redoc-wrapper
          :spec-or-spec-url="'api.yaml'"
          :options="redocOptions"
        ></redoc-wrapper> -->
        <!-- <redoc :spec-or-spec-url="'api.yaml'" :options="redocOptions"></redoc> -->
        <div id="redoc-container"></div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
// import { defineComponent } from "@vue/composition-api";

// import RedocWrapper from "@hnluu8/vue-redoc-wrapper";

import navigation from "@/components/Navigation";
import * as redoc from "redoc/bundles/redoc.standalone.js";
// import RedocWrapper from '@hnluu8/vue-redoc-wrapper'

export default {
  name: "documentation",
  components: {
    // RedocWrapper,
    navigation,
  },
  data() {
    return {
      color: "grey lighten-5",
      flat: true,
      redocOptions: {
        hideDownloadButton: true,
        scrollYOffset: 80,
        theme: {
          logo: { maxWidth: "220px", gutter: "30px" },
        },
      },
    };
  },
  mounted() {
    redoc.init(
      "api.yaml",
      this.redocOptions,
      document.getElementById("redoc-container")
    );
  },
};
</script>

<style lang="scss">
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li:before {
  width: 0px;
  /* list-style-type: none !important; */
}
</style>
