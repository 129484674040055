<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg"
              alt="Logo"
              @click="$router.push({ name: 'home' })"
            />
          </v-list-item-avatar>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, name, link], i) in items"
          :key="i"
          link
          @click="redirectTo({ name: name, hash: link })"
        >
          <!-- <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title color="#000000" class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
        <v-img
          src="https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg"
          max-width="50px"
          @click="$router.push({ name: 'home' })"
        />
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        color="#000000"
        v-if="isXs"
      />
      <div v-else>
        <v-btn
          color="#000000"
          text
          @click="$router.push({ name: 'home', hash: '#features' })"
        >
          <span class="mr-2">Sobre</span>
        </v-btn>
        <v-btn
          color="#000000"
          text
          @click="$router.push({ name: 'documentation' })"
        >
          <span class="mr-2">API doc</span>
        </v-btn>
        <!-- <v-btn
          color="#000000"
          rounded
          outlined
          text
          @click="$router.push({ name: 'home', hash: '#contact' })"
        >
          <span class="mr-2">Contate-nos</span>
        </v-btn> -->
        <v-btn color="#000000" text @click="redirectTo()">
          <span class="mr-2">Login</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-information-outline", "Sobre", "home", "#features"],
      ["mdi-information-outline", "Api doc", "documentation", ""],
      // ["mdi-email-outline", "Contatos", "home", "#contact"],
      ["mdi-email-outline", "Login", "login", "https://"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize: function () {
      this.isXs = window.innerWidth < 850;
    },
    redirectTo: function (params) {
      if (params && !/(http(s?)):\/\//i.test(params.hash)) {
        this.$router.push(params);
      } else {
        window.location.href = process.env.VUE_APP_MPRO_HOME;
      }
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
