import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#119DA4",
        secondary: "#171b34",
        accent: "3D87E4",
      },
    },
  },
});
