import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueYouTubeEmbed from "vue-youtube-embed";
import router from "./router";
import VueGtag from "vue-gtag";

Vue.use(VueYouTubeEmbed);
// Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: {
      //FIXME - change this to env var..
      id: "G-0DSPKVE60E",
    },
    appName: "Messaging PRO",
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate() {
      const trackingData = {
        //FIXME - change this to env var..
        environment: "development",
      };
      return trackingData;
    },
  },
  router
);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
