import Keycloak from "keycloak-js";
export let initOptions = {
  url: process.env.VUE_APP_LOGIN_URL + "/auth/",
  realm: "frontoffice",
  clientId: "account",
  onLoad: "check-sso",
  checkLoginIframe: true,
};

export const keycloakInstance = Keycloak(initOptions);

export default keycloakInstance;
